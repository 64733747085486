<template>
  <div class="weDo">
    <div class="weDo_title">
      What We Do
    </div>
    <div class="banner_box">
      <img src="../assets/image/banner1.png" alt="" />
      <img src="../assets/image/banner2.png" alt="" />
    </div>
    <div class="weDo_content">
      <h1>For Influencers:</h1>
      <p>
        KOLBOX-social is a subsidiary company belonging to HAIMA Media. HAIMA
        Media is founded in 2020, with a wealth of experience in influencer
        marketing, we are helping lots of brands to do influencer content
        strategy, creative campaigns, project management, advertising
        optimization, and data reporting.
      </p>
      <p>
        Through every collaboration pitched, every contract secured, and every
        partnership built, we strive to empower both our influencers and the
        brands we work with to utilize this ever-growing social space for the
        better.
      </p>
      <p>Partner with us if you’re interested in:</p>
      <ul>
        <li>
          Interacting with brands you and your audience love
        </li>
        <li>
          Expanding your profile and growing your audience
        </li>
        <li>
          Increasing rapport with your audience
        </li>
        <li>
          Boosting your income
        </li>
      </ul>
    </div>
    <div class="line"></div>
    <div class="weDo_content">
      <h1>For Brands & Agencies:</h1>
      <p>
        We give brands a creative edge by connecting them with highly
        influential trendsetters who produce original content that drives
        measurable results.
      </p>
      <p>
        Our approach cuts out the babble and gives you the pipeline to drive
        awareness, endorsements, traffic and enthusiastic consumer engagement.
      </p>
      <p>Our activations are creative, custom, scalable, and cost-effective</p>
    </div>
    <div class="line"></div>
  </div>
</template>

<script>
export default {
  setup() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }
};
</script>
<style lang="less" scoped>
.weDo {
  .line {
    margin: 0 auto;
    width: 5.65rem;
    height: 0.01rem;
    background: #999999;
  }
  .weDo_title {
    text-align: center;
    margin: 0.45rem 0;
    font-size: 0.58rem;
    font-family: Arial;
    font-weight: normal;
    color: #000000;
  }
  .banner_box {
    margin: 0 0.4rem;
    img {
      width: 50%;
    }
  }
  .weDo_content {
    padding: 0.4rem;
    h1 {
      font-size: 0.42rem;
      font-family: Arial;
      font-weight: 400;
      color: #000000;
      margin-bottom: 0.35rem;
    }
    p {
      font-size: 0.25rem;
      font-family: Arial;
      font-weight: 400;
      color: #000000;
      line-height: 0.4rem;
      margin-bottom: 0.5rem;
    }
    ul {
      li {
        list-style: disc;
        font-size: 0.21rem;
        line-height: 0.4rem;
        font-family: Arial;
        font-weight: 400;
        color: #000000;
        margin-left: 1rem;
      }
    }
  }
}
</style>
